<template>
  <div class="mobilePage">
    <div class="nav-box"></div>
    <div class="center" @click="getApkInfo(os.isPhone ? 'ios' : 'android')">
      <img class="img" src="@/assets/images/mobile1/logo.png" alt="">
      <img class="textimg" src="@/assets/images/mobile1/text.png" alt="">
      <img class="hat" src="@/assets/images/mobile1/hat.png" alt="" />
      <div class="connect-box">
        <div class="btn1" @click.stop="toTg"></div>
        <div class="btn1" @click.stop="toPotato"></div>
        <div class="btn1" @click.stop="toSWTg"></div>
        <div class="btn1" @click.stop="toQudao"></div>
      </div>
      <!-- <div class="font16">
        <p>Our website is designed for those</p>
        <p>who are eged over 18+year.</p>
        <p>lf you are NOT over 18+</p>
        <p>please leave our website.</p>
      </div>
      <div class="btn">确认-点击免费观看</div>
      <p class="font20">本站含有成人内容，请注意确认</p>
      <div class="font20">
        <p>请确认您 <span>已经年满18+</span></p>
        <p>Are you age over 18+?</p>
      </div>
      <p>*我们是隶属于海外华人图体，并非大陆人士</p>
      <p>本站内容皆是大神真实生活记录，仅供观看</p>
      <p>一切内容符合当地法律法规。</p>
      <p>访问之前请确认您年满18+且不触犯本地法律法规，</p>
      <p>两年半网络上有很多盗版网站，</p>
      <p>我们不会主动联系您，更不会提供银行账号主动要求您汇款</p>
      <p>任何人士用QQ、微信等大陆软件跟您联系的都是骗子</p>
      <p>我们的联系方式只有站内公布的官方客服</p> -->
      <div class="download-btn">
        <img src="@/assets/images/mobile1/ios-btn.png" v-if="os.isPhone" alt="" @click="getApkInfo('ios')" />
        <img src="@/assets/images/mobile1/android-btn.png" @click="getApkInfo('android')" v-else alt="" />
      </div>
      <p>提示：本APP属于特殊行业，偶尔会误报毒提醒，如有报毒提示，</p>
      <p>请点击忽略，并开启飞行模式安装即可！</p>
    </div>

    <!-- 安卓提示框 -->
    <van-overlay :show="androidPromptShow" z-index="999" class="androidOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="androidPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <!-- 苹果提示框 -->
    <van-overlay :show="iosPromptShow" z-index="999" class="iosOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="appName">两年半</div>
          <div class="desc">全免费 更丰富 更高清</div>
          <div class="confirmBtnBox" @click="iosPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <van-popup v-model="showTip" class="downTip">
      <div class="downTipTitle">温馨提示</div>
      <div class="downTipContent">
        请使用Safari浏览器进行下载
      </div>
      <div class="url">{{ currUrl }}</div>
      <div class="downTipConfirm" @click.stop="doCopy">复制链接</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["os"],
  data() {
    return {
      androidPromptShow: false,
      iosPromptShow: false,
      showTip: false,
      currUrl: window.location.href
    };
  },
  mounted() {
    let ua = this.os.userAgent.toLowerCase();
    let isSafari =
      ua.indexOf("safari") !== -1 &&
      ua.indexOf("browser") === -1 &&
      ua.indexOf("android") === -1;
    if (!isSafari && this.os.isPhone) {
      this.showTip = true;
    }
    const referrer = sessionStorage.getItem("referrer");
    const dc = sessionStorage.getItem("dc");
    if (referrer && dc) {
      this.currUrl = referrer + "user.html?dc=" + dc;
    } else {
      if (referrer) {
        this.currUrl = referrer + "user.html";
      } else {
        this.currUrl = window.location.href;
      }
    }
  },
  methods: {
    // 剪贴板
    doCopy() {
      this.$copyText(this.currUrl).then(
        () => {
          console.log("复制成功");
          this.showTip = false;
        },
        () => {
          console.log("复制失败");
        }
      );
    },
    async showInstallTutorial(type) {
      if (type == "ios") {
        this.iosPromptShow = true;
      } else {
        this.androidPromptShow = true;
      }
    },
    async getApkInfo(type) {
      await this.showInstallTutorial(type);
      setTimeout(() => {
        this.$emit("getApkInfo", type);
      }, 500);
    },
    toTg() {
      window.open("https://t.me/liangnianbanlaosiji");
    },
    toPotato() {
      window.open("https://chptdl.org/lnbsq");
    },
    toSWTg() {
      window.open("https://t.me/mu02guang");
    },
    toQudao() {
      window.open("https://t.me/huansong_nangua");
    }
  }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}

.mobilePage {
  height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #3e88ff 0%, #07f1ff 100%);
  position: relative;
  padding: 0 18px;

  .nav-box {
    height: 37px;
  }

  .center {
    width: 100%;
    background: rgba(255, 255, 255, 0.2);
    text-align: center;
    font-size: 10px;
    color: #fff;
    line-height: 14px;
    padding-bottom: 8px;
    position: relative;
    .connect-box {
      width: 55px;
      height: 264px;
      background: url('./../../../assets/images/mobile1/connect.png') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 0;
      right: -18px;
      padding: 30px 6px 0;
      .btn1 {
        width: 43px;
        height: 42px;
        margin-bottom: 12px;
      }
    }
    .img {
      width: 162px;
      height: 54px;
      margin-top: 7px;
      margin-bottom: 37px;
    }
    .hat {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 350px;
      right: 18px;
    }
    .textimg {
      width: 304px;
    }
    .font16 {
      font-size: 16px;
      line-height: 22px;
    }

    .btn {
      width: 188px;
      height: 52px;
      border-radius: 10px;
      line-height: 52px;
      background: #4DF3EB;
      margin: 10px auto;
      color: #003583;
      font-size: 16px;
      font-weight: 600;
    }

    .font20 {
      font-size: 20px;
      margin-bottom: 10px;
      line-height: 28px;

      span {
        color: #F663FF;
      }
    }
  }

  .download-btn {
    width: 120px;
    height: 36px;
    margin: 20px auto 18px;

    img {
      width: 120px;
      height: 36px;
    }
  }

  .androidOverlay {
    display: flex;
    align-items: center;
    justify-content: center;

    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/android_tip.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;

      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }

  .iosOverlay {
    display: flex;
    align-items: center;
    justify-content: center;

    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/iosTip.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      position: relative;

      .appName {
        font-size: 10px;
        font-weight: 500;
        color: #242423;
        position: absolute;
        left: 79px;
        bottom: 102px;
        z-index: 1;
        background-color: #ffffff;
        width: 100px;
      }

      .desc {
        font-size: 7px;
        font-weight: 500;
        color: #8f908f;
        position: absolute;
        left: 79px;
        bottom: 92px;
        z-index: 1;
        background-color: #ffffff;
        width: 100px;
      }

      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
}

.downTip {
  border-radius: 10px;
  padding: 18px;
  box-sizing: border-box;
  text-align: center;

  .downTipTitle {
    font-size: 18px;
    margin-bottom: 34px;
  }

  .downTipContent {
    white-space: nowrap;
    font-size: 15px;
  }

  .url {
    font-size: 15px;
    margin-top: 16px;
    text-align: left;
  }

  .downTipConfirm {
    // margin-top: 12px;
    font-size: 15px;
    width: 160px;
    height: 30px;
    border-radius: 40px;
    margin: 32px auto 0;
    color: #fff;
    background: #5b92ee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
