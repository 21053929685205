<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="title">
      <img class="logo" src="@/assets/images/pc/logo.png" alt="" />
      <div class="navTo-box">
        <div class="item" @click="toTg">
          <img src="@/assets/images/pc/1.png" alt="" />
          <p>官方群组</p>
        </div>
        <div class="item" @click="toPotato">
          <img @click="toTg" src="@/assets/images/pc/2.png" alt="" />
          <p>土豆群组</p>
        </div>
        <div class="item" @click="toSWTg">
          <img src="@/assets/images/pc/3.png" alt="" />
          <p>商务合作</p>
        </div>
        <div class="item" @click="toQudao">
          <img src="@/assets/images/pc/4.png" alt="" />
          <p>渠道合作</p>
        </div>
      </div>
    </div>
    <div class="center">
      <img class="img" src="@/assets/images/pc/text.png" alt="">
      <!-- <p>Our website is designed for those</p>
      <p>who are eged over 18+year.</p>
      <p>lf you are NOT over 18+ please leave our website.</p>
      <div class="btn">确认-点击免费观看</div>
      <p class="padding20">本站含有成人内容，请注意确认</p>
      <p class="font20">请确认您<span>已经年满18+</span></p>
      <p class="padding20">Are you age over 18+?</p>
      <p>*我们是隶属于海外华人图体，并非大陆人士</p>
      <p>本站内容皆是大神真实生活记录，仅供观看</p>
      <p>一切内容符合当地法律法规。</p>
      <p>访问之前请确认您年满18+且不触犯本地法律法规，</p>
      <p>两年半网络上有很多盗版网站，</p>
      <p>我们不会主动联系您，更不会提供银行账号主动要求您汇款</p>
      <p>任何人士用QQ、微信等大陆软件跟您联系的都是骗子</p>
      <p>我们的联系方式只有站内公布的官方客服</p> -->
      <p class="bottom">
        提示：本APP属于特殊行业，偶尔会误报毒提醒，如有报毒提示，请点击忽略，并开启飞行模式安装即可！
      </p>
    </div>
    <div class="qrcode-box">
      <img class="qrcodeBox" :src="qrImg" alt="" />
      <div class="text">扫码下载</div>
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  beforeDestroy() {},
  methods: {
    toTg() {
      window.open("https://t.me/liangnianbanlaosiji");
    },
    toPotato() {
      window.open("https://chptdl.org/lnbsq");
    },
    toSWTg() {
      window.open("https://t.me/mu02guang");
    },
    toQudao() {
      window.open("https://t.me/huansong_nangua");
    }
  }
};
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}

.pcm-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #3e88ff 0%, #07f1ff 100%);
  .title {
    display: flex;
    justify-content: space-between;
    padding: 30px 86px 0;
    align-items: center;
    .logo {
      width: 232px;
      height: 80px;
    }
    .navTo-box {
      display: flex;
      color: #fff;
      .item {
        margin-left: 20px;
        width: 44px;
        cursor: pointer;
        font-size: 10px;
        text-align: center;
        img {
          width: 100%;
          margin-bottom: 5px;
        }
      }
    }
  }
  .center {
    width: 1280px;
    height: 886px;
    background: rgba(255, 255, 255, 0.2);
    margin: auto;
    text-align: center;
    font-size: 24px;
    color: #fff;
    font-weight: 500;
    padding-top: 93px;
    line-height: 34px;
    .img {
      width: 640px;
    }
    .btn {
      width: 400px;
      height: 112px;
      border-radius: 20.31px;
      background: #4df3eb;
      color: #003583;
      font-size: 36px;
      line-height: 112px;
      margin: 20px auto;
      cursor: pointer;
    }
    span {
      color: #f663ff;
    }
    .padding20 {
      padding-bottom: 20px;
      font-size: 20px;
    }
    .font20 {
      font-size: 20px;
    }
    .bottom {
      padding-top: 80px;
      font-size: 20px;
      font-weight: 400;
    }
  }
  .qrcode-box {
    position: fixed;
    bottom: 310px;
    left: 0;
    display: flex;
    .qrcodeBox {
      width: 200px;
      height: 200px;
      background-color: #d9d9d9;
    }
    .text {
      width: 50px;
      height: 200px;
      background-color: #4df3eb;
      color: #0b0b0b;
      font-weight: 500;
      font-size: 24px;
      letter-spacing: 13px;
      writing-mode: vertical-lr;
      padding-left: 10px;
      padding-top: 30px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
</style>
